import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  AppBar,
  Badge,
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  Toolbar,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Get } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import "../commonSCSS/header.scss";
import { logoutUser } from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import { capitalizeFirstLetter } from "../Supporting files/HelpingFunction";
import useIsMobile from "../Supporting files/MobileProvider";
import SearchBar from "./Modals/SearchBar";
import CartSidebar from "./Modals/CartSidebar";

const Header = () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state?.user?.cart);
  const cartTotal = useSelector((state) => state?.user?.cartTotal);
  const wishlistData = useSelector((state) => state?.user?.wishlist);
  const { token, userDetail } = useSelector((state) => state.user.userInfo);
  const [profileDrop, setProfileDrop] = useState(null);
  const openProfile = Boolean(profileDrop);
  const [searchDrop, setSearchDrop] = useState(false);

  const openProfileDrop = (event) => {
    setProfileDrop(event.currentTarget);
  };
  const closeProfileDrop = () => {
    setProfileDrop(null);
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [cartSideBar, setcartSideBar] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleExpand = (item) => {
    setExpandedItems((prev) => ({
      ...prev,
      [item]: !prev[item],
    }));
  };

  const navLinks = [
    { text: "Shop", link: userRoutes.products },
    { text: "Collection", link: null },
    { text: "Size Chart", link: userRoutes.sizeChart },
    { text: "New Arrivals", link: userRoutes.newArrivals },
    { text: "Featured Products", link: userRoutes.featuredProduts },
    { text: "Best Seller", link: userRoutes.bestSeller },
    { text: "Return / Exchange", link: userRoutes.returnExchange },
  ];
  const mobileNavLinks =
    token && Cookies.get("ishnoorauthToken")
      ? [
          { text: "Order", link: userRoutes.orders },
          { text: "Cart", link: userRoutes.cart },
          { text: "Wishlist", link: userRoutes.wishlist },
          { text: "Logout", link: userRoutes.contactUs },
        ]
      : [
          { text: "Login", link: userRoutes.login },
          { text: "Sign Up", link: userRoutes.signUp },
          { text: "About Us", link: userRoutes.aboutUs },
          { text: "Blogs", link: userRoutes.blogs },
        ];

  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [categories, setCategories] = useState([]);
  const [allCollectionData, setallCollectionData] = useState([]);
  const [showFirstMessage, setShowFirstMessage] = useState(true);

  const handleMouseEnter = (menu) => setHoveredMenu(menu);
  const handleMouseLeave = () => setHoveredMenu(null);
  const shopDropdownContent = [
    {
      title: "Shop by Category",
      subtitle: categories.map((data) => ({
        link: `${userRoutes.products}?categoryId=${data?._id}`,
        name: data.title,
      })),
    },
    {
      title: "Shop by Collection",
      subtitle: allCollectionData.map((data) => ({
        link: `${userRoutes.collectionProducts}?collectionId=${data?._id}`,
        name: data.collectionName,
      })),
    },
    {
      title: "All Products",
      subtitle: [
        {
          link: `${userRoutes.products}`,
          name: "View All",
        },
      ],
    },
  ];
  const GetAllCollections = async (page = 0, pageSize = 25) => {
    try {
      const response = await Get(
        `${URL_CONSTANTS.GetAllCollections}?page=${
          page + 1
        }&pageSize=${pageSize}`
      );
      setallCollectionData(response.response);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  useEffect(() => {
    fetchCategories();
    GetAllCollections();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstMessage((prev) => !prev); // Toggle between true and false
    }, 3000); // Change message every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);
  return (
    <>
      <CartSidebar
        open={cartSideBar}
        onClose={() => {
          setcartSideBar(!cartSideBar);
        }}
      />
      <SearchBar open={searchDrop} setOpen={setSearchDrop} />
      <div className="header2">
        <header>
          {!isMobile && (
            <Box className="top-bar">
              {showFirstMessage ? (
                <Typography className="body1">
                  <span className="fw-600">LAUNCH OFFER</span> UPTO 60% OFF
                </Typography>
              ) : (
                <Typography className="body1 uppercase">
                  Free Shipping in India || Shipping Worldwide
                </Typography>
              )}
            </Box>
          )}

          <AppBar position="static" className="header">
            {isMobile ? (
              <>
                <Toolbar className="toolbar">
                  <button
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleMobileMenu}
                    className="menu-icon"
                  >
                    <MenuIcon />
                  </button>
                  <Box
                    className="logo-container py-2"
                    onClick={() => {
                      if (isHomePage) {
                        window.location.reload();
                      } else {
                        navigate(userRoutes.dashboard);
                      }
                    }}
                  >
                    <img src="/images/logo.png" alt="Logo" className="logo" />
                  </Box>
                  <Box className="icons-container">
                    <button
                      className="mt-1"
                      onClick={() => setSearchDrop(true)}
                    >
                      <SearchIcon />
                    </button>
                    <button onClick={() => navigate(userRoutes.wishlist)}>
                      <Badge
                        badgeContent={
                          token && Cookies.get("ishnoorauthToken")
                            ? wishlistData?.length
                            : 0
                        }
                        color="error"
                      >
                        <FavoriteBorderIcon />
                      </Badge>
                    </button>
                    <button onClick={() => setcartSideBar(true)}>
                      <Badge
                        badgeContent={
                          token && Cookies.get("ishnoorauthToken")
                            ? cartData?.products?.length
                            : 0
                        }
                        color="error"
                      >
                        <ShoppingCartIcon />
                      </Badge>
                    </button>
                  </Box>
                </Toolbar>
              </>
            ) : (
              <>
                <Toolbar className="toolbar">
                  <Box
                    className="logo-container"
                    onClick={() => {
                      if (isHomePage) {
                        window.location.reload();
                      } else {
                        navigate(userRoutes.dashboard);
                      }
                    }}
                  >
                    <img src="/images/logo.png" alt="Logo" className="logo" />
                  </Box>
                  <Box className="icons-container">
                    <button onClick={() => setSearchDrop(true)}>
                      <SearchIcon />
                    </button>
                    <button onClick={openProfileDrop}>
                      <PersonIcon />
                    </button>
                    <button onClick={() => navigate(userRoutes.wishlist)}>
                      <Badge
                        badgeContent={
                          token && Cookies.get("ishnoorauthToken")
                            ? wishlistData?.length
                            : 0
                        }
                        color="error"
                      >
                        <FavoriteBorderIcon />
                      </Badge>
                    </button>
                    {/* <button>
                      <FlagIcon />
                    </button> */}
                    <button onClick={() => setcartSideBar(true)}>
                      <Badge
                        badgeContent={
                          token && Cookies.get("ishnoorauthToken")
                            ? cartData?.products?.length
                            : 0
                        }
                        color="error"
                      >
                        <ShoppingCartIcon />
                      </Badge>
                    </button>
                    <Typography variant="body2" className="amount">
                      {" "}
                      {token &&
                        Cookies.get("ishnoorauthToken") &&
                        cartTotal > 0 &&
                        `₹ ${cartTotal}`}
                    </Typography>
                    <Menu
                      className="profileDropMenu"
                      anchorEl={profileDrop}
                      id="account-menu"
                      open={openProfile}
                      onClose={closeProfileDrop}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
                          borderRadius: "8px",
                          mt: 1,
                          "& .MuiAvatar-root": {
                            width: 22,
                            height: 22,
                            mr: 1,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <div
                        className="profile_drop_content"
                        onClick={closeProfileDrop}
                      >
                        {token && Cookies.get("ishnoorauthToken") ? (
                          <>
                            <p className="wc mb-3">{`Welcome ${capitalizeFirstLetter(
                              userDetail?.firstName
                            )}`}</p>
                            <p className="common_page sub mb-5">
                              {userDetail?.email}
                            </p>
                            <p
                              className="common_page mb-5"
                              onClick={() => navigate(userRoutes.orders)}
                            >
                              Orders
                            </p>
                            <p
                              className="common_page mb-5"
                              onClick={() => navigate(userRoutes.wishlist)}
                            >
                              {" "}
                              Wishlist
                            </p>
                            <p
                              className="common_page mb-3"
                              onClick={() => navigate(userRoutes.contactUs)}
                            >
                              Contact Us
                            </p>
                            <hr className="hrTag mb-3"></hr>
                            <p
                              className="common_page logout"
                              onClick={() => {
                                closeProfileDrop();
                                dispatch(logoutUser());
                                navigate(userRoutes.dashboard);
                              }}
                            >
                              Logout
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="wc mb-3">Welcome</p>
                            <p className="common_page sub mb-5">
                              To access account and manage order
                            </p>
                            <div className="acn_btn df mb-5">
                              <p
                                className="login mr-5"
                                onClick={() => navigate(userRoutes.login)}
                              >
                                Login
                              </p>
                              <p
                                className="login"
                                onClick={() => navigate(userRoutes.signUp)}
                              >
                                Signup
                              </p>
                            </div>
                            <p
                              className="common_page mb-5"
                              onClick={() => navigate(userRoutes.blogs)}
                            >
                              Blogs
                            </p>
                            <p
                              className="common_page mb-5"
                              onClick={() => navigate(userRoutes.aboutUs)}
                            >
                              About Us
                            </p>
                            <p
                              className="common_page"
                              onClick={() => navigate(userRoutes.contactUs)}
                            >
                              Contact Us
                            </p>
                          </>
                        )}
                      </div>
                    </Menu>
                  </Box>
                </Toolbar>
                <Box className="nav_links">
                  {navLinks.map((text) => (
                    <Typography
                      key={text?.text}
                      className="nav_link"
                      onMouseEnter={() => handleMouseEnter(text?.text)}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                        if (text?.link) {
                          navigate(text?.link);
                        }
                      }}
                    >
                      {text?.text}
                      {text?.text === "Shop" && hoveredMenu === "Shop" && (
                        <Box
                          className="dropdown"
                          onMouseEnter={() => handleMouseEnter("Shop")}
                          onMouseLeave={() => handleMouseLeave()}
                        >
                          {shopDropdownContent.map((itm, idx) => (
                            <Box key={idx} className="dropdown-column">
                              <Typography className="dropdown-heading">
                                {itm.title}
                              </Typography>
                              {itm.subtitle.map((content, index) => (
                                <Typography
                                  key={index}
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(content.link);
                                  }}
                                >
                                  {content.name}
                                </Typography>
                              ))}
                            </Box>
                          ))}
                        </Box>
                      )}
                      {text?.text === "Collection" &&
                        hoveredMenu === "Collection" && (
                          <Box
                            className="dropdown"
                            onMouseEnter={() => handleMouseEnter("Collection")}
                            onMouseLeave={() => handleMouseLeave()}
                          >
                            <Box className="coll_img">
                              {allCollectionData.slice(0, 3)?.map((item, i) => (
                                <Box
                                  className="dropdown-item"
                                  width={"25%"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(
                                      `${userRoutes.collectionProducts}?collectionId=${item._id}`
                                    );
                                  }}
                                >
                                  <img
                                    src={item.collectionImage}
                                    alt="Lucknow Chikan"
                                    className="dropdown-img"
                                  />
                                  <Typography className="coll_name">
                                    {item.collectionName}
                                  </Typography>
                                </Box>
                              ))}
                              <Box className="dropdown-column" width={"25%"}>
                                <Typography className="dropdown-heading">
                                  sale
                                </Typography>
                                <Typography
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`${userRoutes.bestSeller}`);
                                  }}
                                >
                                  Best Seller
                                </Typography>
                                <Typography
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`${userRoutes.newArrivals}`);
                                  }}
                                >
                                  new arrivals
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                    </Typography>
                  ))}
                </Box>
              </>
            )}
          </AppBar>
          <Drawer
            anchor="left"
            open={mobileMenuOpen}
            onClose={toggleMobileMenu}
            className="mobile-menu-header"
          >
            <Box className="drawer-header">
              <Typography variant="h6">Menu</Typography>
              <IconButton onClick={toggleMobileMenu}>
                <CloseIcon />
              </IconButton>
            </Box>

            <List className="drawer-list">
              <ListItem
                onClick={(e) => {
                  setMobileMenuOpen(!mobileMenuOpen);
                  e.stopPropagation();
                  navigate(`${userRoutes.newArrivals}`);
                }}
              >
                <ListItemText primary="New Arrivals" className="uppercase" />
                <Typography variant="caption" className="tag-yellow">
                  NEW
                </Typography>
              </ListItem>
              <ListItem
                onClick={(e) => {
                  setMobileMenuOpen(!mobileMenuOpen);
                  e.stopPropagation();
                  navigate(`${userRoutes.bestSeller}`);
                }}
              >
                <ListItemText primary="Best Seller" className="uppercase" />
                <Typography variant="caption" className="tag-green">
                  NEW
                </Typography>
              </ListItem>

              <ListItem button onClick={() => handleExpand("collection")}>
                <ListItemText primary="Collection" className="uppercase" />
                {expandedItems.collection ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={expandedItems.collection}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding className="nested-list">
                  {allCollectionData.map((data) => (
                    <ListItem
                      className="uppercase"
                      key={data.collectionName}
                      onClick={(e) => {
                        setMobileMenuOpen(!mobileMenuOpen);
                        e.stopPropagation();
                        navigate(
                          `${userRoutes.collectionProducts}?collectionId=${data._id}`
                        );
                      }}
                    >
                      <ListItemText
                        className="uppercase"
                        primary={data.collectionName.toLowerCase()}
                        sx={{ textTransform: "capitalize" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <ListItem
                button
                onClick={() => handleExpand("category")}
                className="uppercase"
              >
                <ListItemText primary="Category" className="uppercase" />
                {expandedItems.category ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={expandedItems.category}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding className="nested-list">
                  {categories.map((data) => (
                    <ListItem
                      className="uppercase"
                      key={data.title}
                      onClick={(e) => {
                        setMobileMenuOpen(!mobileMenuOpen);
                        e.stopPropagation();
                        navigate(
                          `${userRoutes.products}?categoryId=${data?._id}`
                        );
                      }}
                    >
                      <ListItemText
                        className="uppercase"
                        primary={data.title.toLowerCase()}
                        sx={{ textTransform: "capitalize" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>

              {mobileNavLinks.map((data) => (
                <ListItem
                  button
                  key={data.text}
                  onClick={(e) => {
                    setMobileMenuOpen(!mobileMenuOpen);
                    e.stopPropagation();
                    if (data.text === "Logout") {
                      dispatch(logoutUser());
                      navigate(userRoutes.dashboard);
                    } else if (data.text === "Cart") {
                      setcartSideBar(true);
                    } else {
                      navigate(data.link);
                    }
                  }}
                >
                  <ListItemText primary={data.text} className="uppercase" />
                </ListItem>
              ))}

              {/* Contact Us Section */}
              <ListItem
                onClick={(e) => {
                  setMobileMenuOpen(!mobileMenuOpen);
                  e.stopPropagation();
                  navigate(userRoutes.contactUs);
                }}
              >
                <ListItemText
                  primary="Contact Us"
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
              </ListItem>
              <Box className="contact-section">
                <Typography variant="body2">Need help?</Typography>
                <Typography variant="body2">📧 support@ishnoor.in</Typography>
              </Box>
            </List>
          </Drawer>
        </header>
      </div>
    </>
  );
};

export default Header;
