import {
  Box,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  Modal,
  Button,
  Autocomplete,
} from "@mui/material";
import {
  Close as CloseIcon,
  RemoveCircle as RemoveCircleIcon,
} from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import ImageUploader from "../components/ImageUploader";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
import toast from "react-hot-toast";
import { Get, Post, Put } from "../Api/api";
import RichTextEditor from "../components/RichTextEditor";
import SDSelectDropdown from "../Supporting files/SDSelectDropdown/SDSelectDropdown";

function EditProductPopup({ open, handleClose, productData }) {
  const [editorData, setEditorData] = useState(
    "<b>write product description here</b>"
  );
  const [editorDataShort, setEditorDataShort] = useState(
    "<b>write product Short description here</b>"
  );
  const [allCollection, setallCollection] = useState([]);

  const [formValues, setFormValues] = useState({
    title: productData.title,
    description: productData.description,
    collection: productData.collectionId,
    categories: productData.categories,
    brand: productData.brand,
    isFeatured: false,
    status: 1,
    variants: productData.variants,
  });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [colorPickerVisible, setColorPickerVisible] = useState(null);

  const getAllCollections = async (page = 0, pageSize = 25) => {
    try {
      const response = await Get(
        `${ADMINURL_CONSTANTS.GetAllCollections}?page=${
          page + 1
        }&pageSize=${pageSize}`
      );
      const categories = response.response.map((category) => ({
        label: category.collectionName,
        value: category._id,
      }));
      setallCollection(categories);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const handleSelectChange = (selectedValue) => {
    setFormValues((prev) => ({
      ...prev,
      collection: selectedValue, // Update the state with the selected value
    }));
  };

  useEffect(() => {
    getAllCollections();
    axios
      .get(ADMINURL_CONSTANTS.GetAllCategory)
      .then((response) => {
        const categories = response.data.response?.map((category) => ({
          _id: category._id,
          title: category.title,
        }));
        setCategoryOptions(categories);
      })
      .catch((error) => {
        console.error("Error fetching categories", error);
      });
  }, []);

  // useEffect(() => {
  //     setFormValues((prev) => ({
  //       ...prev,
  //       ...productData,
  //       collection: productData.collectionId,
  //     }));
  //     if (productData.description) {
  //       setEditorDataShort(productData?.short_description ?? "");
  //       setEditorData(productData.description ?? "");
  //     }

  // }, [productData]);
  useEffect(() => {
    setFormValues(productData);
    if (productData.description) {
      setEditorDataShort(productData?.short_description ?? "");
      setEditorData(productData.description ?? "");
    }
  }, [open, productData]);

  const UpdateProduct = async () => {
    if (!validateForm()) {
      return toast.error("Please fill all mandatory fields.");
    }
    let payload = { ...formValues };
    try {
      const response = await Put(
        `${ADMINURL_CONSTANTS.UpdateProduct}/${productData._id}`,
        payload
      );
      toast.success("Product updated successfully.");
      handleClose();
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCategoryChange = (event, newValue) => {
    setFormValues({
      ...formValues,
      categories: newValue,
    });
  };

  const handleVariantChange = (index, e) => {
    const { name, value } = e.target;
    const variants = [...formValues.variants];
    variants[index][name] = value;
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const handleColorChange = (index, color) => {
    const variants = [...formValues.variants];
    variants[index].color = color.hex;
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const removeSize = (variantIndex, sizeIndex) => {
    const variants = [...formValues.variants];
    if (variants[variantIndex].sizes.length > 1) {
      variants[variantIndex].sizes.splice(sizeIndex, 1);
      setFormValues({
        ...formValues,
        variants,
      });
    }
  };

  const addVariant = () => {
    setFormValues({
      ...formValues,
      variants: [
        ...formValues.variants,
        {
          color: "",
          images: [],
          sizes: [
            {
              size: "",
              price: "",
              dimensions: "",
              quantity: "",
              mrp: "",
              discount: "",
              isNew: true,
            },
          ],
          isNew: true,
        },
      ],
    });
  };

  const addSize = (variantIndex) => {
    const variants = [...formValues.variants];
    variants[variantIndex].sizes.push({
      size: "",
      price: "",
      dimensions: "",
      quantity: "",
      mrp: "",
      discount: "",
      isNew: true,
    });
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const handleSizeChange = (variantIndex, sizeIndex, e) => {
    const { name, value } = e.target;
    const variants = [...formValues.variants];
    variants[variantIndex].sizes[sizeIndex][name] = value;
    setFormValues({
      ...formValues,
      variants,
    });
  };

  const handleImageUploadSuccess = (variantIndex, uploadedUrls) => {
    setFormValues((prevFormValues) => {
      // Create a copy of the current variants
      const updatedVariants = [...prevFormValues.variants];

      // Update the specific variant at variantIndex
      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],
        images: [
          ...updatedVariants[variantIndex].images, // Existing images
          ...uploadedUrls, // New uploaded URLs
        ],
      };

      // Return the updated form values
      return {
        ...prevFormValues,
        variants: updatedVariants,
      };
    });
  };

  const handleImageDelete = (variantIndex, imageIndex) => {
    setFormValues((prevFormValues) => {
      const updatedVariants = [...prevFormValues.variants];
      updatedVariants[variantIndex].images = updatedVariants[
        variantIndex
      ].images.filter((_, idx) => idx !== imageIndex);
      return {
        ...prevFormValues,
        variants: updatedVariants,
      };
    });
  };

  const validateForm = () => {
    let valid = true;
    formValues.variants.forEach((variant, variantIndex) => {
      if (!variant.color) {
        toast.error(`Variant ${variantIndex + 1}: Color is required.`);
        valid = false;
      }
      if (!variant.images.length) {
        toast.error(`Variant ${variantIndex + 1}: Images are required.`);
        valid = false;
      }
      variant.sizes.forEach((size, sizeIndex) => {
        if (!size.size) {
          toast.error(
            `Variant ${variantIndex + 1}, Size ${
              sizeIndex + 1
            }: Size is required.`
          );
          valid = false;
        }
        if (!size.price) {
          toast.error(
            `Variant ${variantIndex + 1}, Size ${
              sizeIndex + 1
            }: Price is required.`
          );
          valid = false;
        }
        if (!size.quantity) {
          toast.error(
            `Variant ${variantIndex + 1}, Size ${
              sizeIndex + 1
            }: Quantity is required.`
          );
          valid = false;
        }
        if (!size.mrp) {
          toast.error(
            `Variant ${variantIndex + 1}, Size ${
              sizeIndex + 1
            }: MRP is required.`
          );
          valid = false;
        }
      });
    });

    return valid;
  };
  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Container
        maxWidth="md"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "24px",
          height: "100vh",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <IconButton
          onClick={() => {
            handleClose();
            setFormValues({
              title: "",
              description: "",
              collection: "",
              categories: [],
              brand: "",
              isFeatured: false,
              status: 1,
              variants: [],
            });
          }}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1301,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Edit Product
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Product Title"
                  name="title"
                  value={formValues.title}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RichTextEditor
                  content={editorData}
                  onChange={(data) => {
                    setFormValues({ ...formValues, description: data });
                    setEditorData(data);
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: formValues.description }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RichTextEditor
                  content={editorDataShort}
                  onChange={(data) => {
                    setFormValues({ ...formValues, short_description: data });
                    setEditorDataShort(data);
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: formValues.short_description,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  options={categoryOptions}
                  getOptionLabel={(option) => option.title}
                  value={formValues.categories}
                  onChange={handleCategoryChange}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SDSelectDropdown
                  label="Select Collection"
                  value={formValues.collection} // Bind value to state
                  options={allCollection} // Provide options array
                  onChange={handleSelectChange} // Handle value change
                  helperText="Please select a collection"
                  error={formValues.collection === ""} // Show error if no value selected
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Brand"
                  name="brand"
                  value={formValues.brand}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Variants</Typography>
                {formValues.variants?.map((variant, variantIndex) => (
                  <Box
                    key={variantIndex}
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "16px",
                      mb: 2,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Select Color"
                          // disabled={variant.isNew === undefined ? true : false}
                          name="color"
                          value={variant.color}
                          onChange={(e) => handleVariantChange(variantIndex, e)}
                          margin="normal"
                          required
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                // disabled={
                                //   variant.isNew === undefined ? true : false
                                // }
                                onClick={() =>
                                  setColorPickerVisible(variantIndex)
                                }
                              >
                                <div
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: "50%",
                                    backgroundColor: variant.color || "#c42f0f",
                                  }}
                                />
                              </IconButton>
                            ),
                            // readOnly: !variant.isNew,
                          }}
                        />
                        {colorPickerVisible === variantIndex && (
                          <SketchPicker
                            color={variant.color}
                            onChangeComplete={(color) =>
                              handleColorChange(variantIndex, color)
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ImageUploader
                          onUploadSuccess={(uploadedUrls) => {
                            if (uploadedUrls.length > 0) {
                              handleImageUploadSuccess(
                                variantIndex,
                                uploadedUrls
                              );
                            }
                          }}
                        />
                      </Grid>
                      {variant?.sizes?.map((size, sizeIndex) => (
                        <Grid container spacing={2} key={sizeIndex}>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              fullWidth
                              label="Size"
                              name="size"
                              value={size.size}
                              onChange={(e) =>
                                handleSizeChange(variantIndex, sizeIndex, e)
                              }
                              margin="normal"
                              required={size.isNew}
                              // InputProps={{
                              //   readOnly: !size.isNew,
                              // }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Price"
                              name="price"
                              value={size.price}
                              onChange={(e) =>
                                handleSizeChange(variantIndex, sizeIndex, e)
                              }
                              margin="normal"
                              required
                              //   InputProps={{
                              //     readOnly: !size.isNew,
                              //   }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              fullWidth
                              type="number"
                              label="Quantity"
                              name="quantity"
                              value={size.quantity}
                              onChange={(e) => {
                                if (+e.target.value >= 0) {
                                  handleSizeChange(variantIndex, sizeIndex, e);
                                }
                              }}
                              margin="normal"
                              required
                              //   InputProps={{
                              //     readOnly: !size.isNew,
                              //   }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              fullWidth
                              type="number"
                              label="MRP"
                              name="mrp"
                              value={size.mrp}
                              onChange={(e) =>
                                handleSizeChange(variantIndex, sizeIndex, e)
                              }
                              margin="normal"
                              required
                              //   InputProps={{
                              //     readOnly: !size.isNew,
                              //   }}
                            />
                          </Grid>
                          {variant.isNew && (
                            <Grid item xs={12} sm={12}>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() =>
                                  removeSize(variantIndex, sizeIndex)
                                }
                                startIcon={<RemoveCircleIcon />}
                              >
                                Remove Size
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                      {variant.images.length > 0 &&
                        variant.images.map((img, idx) => (
                          <Grid item key={idx} style={{ position: "relative" }}>
                            <img
                              loading="eager"
                              src={img}
                              alt={`Variant Image ${idx + 1}`}
                              style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "8px",
                                borderRadius: "4px",
                              }}
                            />
                            <IconButton
                              onClick={() =>
                                handleImageDelete(variantIndex, idx)
                              }
                              style={{
                                position: "absolute",
                                top: 4,
                                right: 4,
                                padding: 0,
                                color: "red",
                              }}
                            >
                              <CloseIcon style={{ fontSize: "16px" }} />
                            </IconButton>
                          </Grid>
                        ))}
                      <Grid xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addSize(variantIndex)}
                        >
                          Add Size
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addVariant}
                >
                  Add Variant
                </Button>
              </Grid>
              <Grid item xs={12} textAlign={"center"}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={UpdateProduct}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Modal>
  );
}

export default EditProductPopup;
