import { Box, Button, Typography, Divider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import {
  formatDate,
  formatOrderStatus,
  truncateDescription,
} from "../Supporting files/HelpingFunction";
import Footer from "../components/Footer";
import Loader from "../components/Loader/Loader";
import CreateNewAddress from "../components/Modals/CreateNewAddress";
import { updateCart, updateCartAmount } from "../Redux/Slice/UserSlice";
import { useDispatch } from "react-redux";

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allOrdersData, setallOrdersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newAddr, setNewAddr] = useState(false);

  useEffect(() => {
    getAllOrders();
    fetchCart();
  }, []);

  const getAllOrders = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.ALLOrders}`);
      setallOrdersData(response.response);
      setLoading(false);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const handleContinueShopping = () => {
    navigate(userRoutes.products); // Redirect to the shopping page
  };

  const handleAddAddress = () => {
    setNewAddr(true); // Redirect to the address page
  };

  const fetchCart = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getCartItems}`);
      const products = response?.response?.products || [];
      dispatch(updateCart(response?.response));
      dispatch(
        updateCartAmount(
          products.reduce(
            (total, data) => total + +data.productDetail.variants.sizes.price,
            0
          )
        )
      );
    } catch (error) {
      dispatch(updateCart([]));
    }
  };

  return (
    <>
      {loading && <Loader />}
      <CreateNewAddress open={newAddr} setOpen={setNewAddr} />

      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="orderSection">
          {allOrdersData?.length > 0 ? (
            allOrdersData.map((order) => {
              const deliveryDate = new Date(order.deliveryTime);
              const currentDate = new Date();

              // Calculate the difference in days
              const differenceInDays = Math.floor(
                (currentDate - deliveryDate) / (1000 * 60 * 60 * 24)
              );
              return (
                <Box className="section" key={order?._id}>
                  <Grid container padding={0}>
                    <Grid xs={12} className="del_status mb-4">
                      <h3
                        className={`${
                          order?.deliveryStatus === "order_cancelled"
                            ? "cancelled"
                            : order?.deliveryStatus === "order_processing"
                            ? "processing"
                            : "Delivered"
                        }`}
                      >
                        {formatOrderStatus(order?.deliveryStatus)}
                      </h3>
                      <p className="date">On {formatDate(order?.updatedAt)}</p>
                    </Grid>
                    <Grid xs={12} className="del_details">
                      <img
                        loading="eager"
                        src="images/order_next.svg"
                        alt="r"
                        onClick={() =>
                          navigate(`${userRoutes.orderDetail}?id=${order?._id}`)
                        }
                        className="order_img"
                      />
                      <img
                        loading="eager"
                        src={
                          order?.products[0]?.productDetail?.variants?.images[0]
                        }
                        alt="r"
                        className="del_img"
                      />
                      <div className="details pr-10 w-100">
                        <h6 className="title mb-3">
                          {order?.products[0]?.productDetail?.title}
                        </h6>
                        <h6
                          className="desc mb-3"
                          dangerouslySetInnerHTML={{
                            __html: truncateDescription(
                              order?.products[0]?.productDetail
                                ?.short_description,
                              150
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                    {order.deliveryStatus === "delivered" && (
                      <Grid xs={12} className="Exchange">
                        {differenceInDays <= 7 ? (
                          <>
                            Exchange/Return window will close in 7 days{" "}
                          ({7 - differenceInDays} days left.)
                          </>
                        ) : (
                          <>Exchange/Return window is closed.</>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              );
            })
          ) : (
            <Box display="flex" flexDirection="column" padding="2rem">
              {/* Section 1: No Orders */}
              <Box textAlign="left" marginBottom="4rem">
                {/* Header */}
                <Typography
                  variant="h5"
                  fontWeight="600"
                  style={{
                    color: "#8e282b",
                    marginBottom: "0.5rem",
                  }}
                >
                  My Orders
                </Typography>
                {/* Divider */}
                <Divider
                  sx={{
                    borderColor: "#8e282b",
                    marginBottom: "1.5rem",
                  }}
                />
                {/* Message */}
                <Typography
                  variant="body1"
                  style={{
                    color: "#333",
                    marginBottom: "1rem",
                  }}
                >
                  You haven&apos;t placed any orders yet.
                </Typography>
                {/* Button */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#8e282b",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#731e22",
                    },
                  }}
                  onClick={handleContinueShopping}
                >
                  Continue Shopping
                </Button>
              </Box>

              {/* Section 2: Add Default Address */}
              <Box textAlign="left" marginTop={"4rem"}>
                {/* Header */}
                <Typography
                  variant="h5"
                  fontWeight="600"
                  style={{
                    color: "#8e282b",
                    marginBottom: "0.5rem",
                  }}
                >
                  Default Address
                </Typography>
                {/* Divider */}
                <Divider
                  sx={{
                    borderColor: "#8e282b",
                    marginBottom: "1.5rem",
                  }}
                />
                {/* Message */}
                <Typography
                  variant="body1"
                  style={{
                    color: "#333",
                    marginBottom: "1rem",
                  }}
                >
                  Add your default address.
                </Typography>
                {/* Button */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#8e282b",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#731e22",
                    },
                  }}
                  onClick={handleAddAddress}
                >
                  Add Address
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Orders;
