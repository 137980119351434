//const BASE_URL = process.env.REACT_APP_BACKEND_URL;
//const BASE_URL = "http://ec2-3-27-124-225.ap-southeast-2.compute.amazonaws.com";
const BASE_URL = "https://ishnoor.in/api";

export const URL_CONSTANTS = {
  Login_user: `${BASE_URL}/createCategory`,
  getAllCategories: `${BASE_URL}/categories`,
  getNewArrival: `${BASE_URL}/products/new-arrivals`,
  getBestSeller: `${BASE_URL}/products/best-sellers`,
  getfeatured: `${BASE_URL}/products/featured-products`,
  getAllProducts: `${BASE_URL}/products`,
  getWishlist: `${BASE_URL}/wishlist`,
  deleteWishlist: `${BASE_URL}/wishlist`,
  AddToWishlist: `${BASE_URL}/wishlist`,
  getBlogs: `${BASE_URL}/blogs`,
  getCartItems: `${BASE_URL}/cart`,
  AddCartItems: `${BASE_URL}/cart`,
  removeCartItems: `${BASE_URL}/cart/remove-product`,
  moveToWishList: `${BASE_URL}/wishlist/move-from-cart`,
  getProductState: `${BASE_URL}/products/filter-stats`,
  productDetails: `${BASE_URL}/products/`,
  productReview: `${BASE_URL}/products/get-all-review/`,
  login: `${BASE_URL}/auth/login`,
  googlelogin: `${BASE_URL}/auth/google-auth`,
  facebooklogin:`${BASE_URL}/auth/user/facebook`,
  signUp: `${BASE_URL}/auth/signup`,
  verifyotp: `${BASE_URL}/auth/verify-otp`,
  createOrder: `${BASE_URL}/orders`,
  addAddress: `${BASE_URL}/users/delivery-address`,
  removeAddress: `${BASE_URL}/users/delivery-address/`,
  imageUploader: `${BASE_URL}/uploads`,
  searchProducts: `${BASE_URL}/products/search-product?keyword=`,
  ALLOrders: `${BASE_URL}/orders/my`,
  OrdersDetails: `${BASE_URL}/orders/my-order-detail/`,
  paymentCapture: `${BASE_URL}/payment/paymentCapture`,
  newsLetter: `${BASE_URL}/users/newsLetter/subscribe`,
  writeReview: `${BASE_URL}/products/add-review`,
  updateReview: `${BASE_URL}/products/update-review`,
  forgetPassword: `${BASE_URL}/auth/forget-password`,
  resetPassword: `${BASE_URL}/auth/reset-password`,
  contactUs: `${BASE_URL}/auth/contact-us`,
  returnRequest: `${BASE_URL}/orders/return-order`,
  GetAllCollections: `${BASE_URL}/categories/collection`,
};
