export const DeliveryStatus = {
  ORDER_PLACED: "order_placed",
  ORDER_PROCESSING: "order_processing",
  PACKAGING: "packaging",
  ON_THE_ROAD: "on_the_road",
  DELIVERED: "delivered",
  CANCELLED: "order_cancelled",
};
export const ReturnStatus = {
  Requested: "requested",
  Accepted: "accepted",
  Rejected: "rejected",
  Completed: "completed",
};
