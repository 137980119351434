import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Delete, Get, Post } from "../Api/api";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import {
  capitalizeFirstLetter,
  formatCurrency,
} from "../Supporting files/HelpingFunction";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductsList from "../components/ProductsList";
import Loader from "./../components/Loader/Loader";

const Wishlist = () => {
  const cartData = useSelector((state) => state?.user?.cart?.products);
  const wishlistData = useSelector((state) => state?.user?.wishlist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const location = useLocation();
  const isWishlist = location.pathname === userRoutes.wishlist;
  //Get all wishlist list
  const fetchWishList = async () => {
    setIsLoader(true);
    try {
      const response = await Get(`${URL_CONSTANTS.getWishlist}`);
      setWishlist(response.response.products);
      setIsLoader(false);
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      setWishlist([]);
      setIsLoader(false);
      console.error("Error ", error.message);
    }
  };

  useEffect(() => {
    if (isWishlist) {
      fetchWishList();
    }
  }, [wishlistData.length]);

  const deleteWishList = async (productId, variantId, sizeId) => {
    let payload = {
      productId,
      variantId,
      sizeId,
    };

    try {
      const response = await Delete(`${URL_CONSTANTS.deleteWishlist}`, payload);
      fetchWishList();
      toast.success("Wishlist updated successfully");
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const addToCart = async (productId, variantId, sizeId) => {
    let dto = {
      productId,
      variantId,
      sizeId,
      quantity: 1,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      toast.success("Cart updated successfully");
      dispatch(updateCart(response.response));
    } catch (error) {
      console.error("Error ", error);
    }
  };
  useEffect(() => {
    fetchWishList();
  }, []);

  return (
    <>
      {isLoader && <Loader />}
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="wishlistSection">
          <h2 className="theme_heading uppercase">Wishlist</h2>
          <Box>
            {wishlist.length > 0 ? (
              <Grid container columnSpacing={3} className="wish_box">
                {wishlist.map((data) => {
                  let is_exist = cartData?.some(
                    (productData) => productData.sizeId === data.sizeId
                  );
                  let quantity = Number(
                    data?.productDetail?.variants?.sizes?.quantity
                  );
                  return (
                    <Grid xs={6} sm={6} md={4} lg={3} className="follow mb-3">
                      <img
                        loading="eager"
                        src={data?.productDetail?.variants.images[0]}
                        alt="insta"
                        className="follow-img"
                        onClick={() => {
                          navigate(
                            `${userRoutes.productDetail}#productId=${data.productId}`
                          );
                        }}
                      />
                      <Grid xs={12} className="text-center">
                        <div
                          className="fav"
                          onClick={() => {
                            deleteWishList(
                              data.productId,
                              data.variantId,
                              data.sizeId
                            );
                          }}
                        >
                          <img
                            loading="eager"
                            src="images/fav.svg"
                            alt="svg"
                            height={"20px"}
                            width={"22px"}
                          />
                        </div>
                        <div className="desc">
                          <p>
                            {capitalizeFirstLetter(data?.productDetail?.title)}
                          </p>
                          <p className="rate">
                            {formatCurrency(
                              data?.productDetail?.variants?.sizes?.price
                            )}
                          </p>
                          <Grid xs={12} className="ctaBtn mt-2 text-center">
                            <Button
                              variant="contained"
                              className="whiteBtn"
                              onClick={() => {
                                if (!is_exist && quantity > 0) {
                                        window.fbq("track", "AddToCart", {
                                          content_ids: data._id,
                                          content_type: "product",
                                          currency: "INR",
                                          value:
                                            data?.productDetail?.variants?.sizes
                                              ?.price,
                                        });
                                  addToCart(
                                    data.productId,
                                    data.variantId,
                                    data.sizeId
                                  );
                                }
                              }}
                            >
                              {is_exist
                                ? "Added to cart"
                                : quantity < 1
                                ? "Out of stock"
                                : "Add to cart"}
                            </Button>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid xs={12}>
                <Box className="empty_cart_wishlish">
                  <h4>Wishlist is Empty</h4>
                  <p>
                    Don’t let your favourites slip away – grab them before
                    they’re gone!
                  </p>
                  <div className="ctaBtn">
                    <Button
                      className="submitBtn"
                      onClick={() => navigate(userRoutes.products)}
                    >
                      Continue Shopping
                    </Button>
                  </div>
                </Box>
              </Grid>
            )}
          </Box>
        </Box>
        <ProductsList
          apiUrl={URL_CONSTANTS.getNewArrival}
          text1={"New"}
          text2={"Arrivals"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.newArrivals}
        />
        <ProductsList
          apiUrl={URL_CONSTANTS.getBestSeller}
          text1={"Best"}
          text2={"Seller"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.bestSeller}
        />

        <Footer />
      </Box>
    </>
  );
};

export default Wishlist;
