import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get, Post } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import Loader from "../components/Loader/Loader";
import {
  setUserData,
  updateCart,
  updateWishlist,
} from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import { validateEmail } from "../Supporting files/HelpingFunction";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
// import loginBg from '../../public/images/login_bg.png';
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
// import GoogleLogin from "react-google-login";
import FacebookIcon from "@mui/icons-material/Facebook";
import { FacebookProvider, LoginButton } from "react-facebook";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [showError, setshowError] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(true);

  const handleLogin = async () => {
    if (!isTermsAccepted) {
      return toast.error("Please accept the Terms and Conditions");
    }
    if (!validateEmail(email) || !password) {
      setshowError(true);
      return seterrorMessage("Please enter valid email and password");
    }
    setshowError(false);
    setLoading(true);
    const payload = {
      email: email,
      password: password,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.login}`,
        JSON.stringify(payload)
      );
      const { token } = response.response;
      dispatch(setUserData(response.response));
      setLoading(false);
      handleRedirection();
      Cookies.set("ishnoorauthToken", token, { expires: 1 });
      toast.success("Logged in successfully");
      setTimeout(() => {
        fetchWishList();
        fetchCart();
      }, 1500);
    } catch (error) {
      console.error("Error ", error.response.data.message);
      seterrorMessage(error.response.data.message);
      setshowError(true);
      setLoading(false);
    }
  };
  //Get all wishlist list
  const fetchWishList = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getWishlist}`);
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };
  const fetchCart = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getCartItems}`);
      dispatch(updateCart(response.response));
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  const googleResponse = async (response) => {
    if (response.credential) {
      try {
        const apiResponse = await Post(`${URL_CONSTANTS.googlelogin}`, {
          token: response.credential,
        });
        console.log("response", apiResponse);

        const { token } = apiResponse.response;
        dispatch(setUserData(apiResponse.response));
        setLoading(false);
        handleRedirection();
        Cookies.set("ishnoorauthToken", token, { expires: 1 });
        toast.success("Logged in successfully");
        setTimeout(() => {
          fetchWishList();
          fetchCart();
        }, 1500);
      } catch (error) {
        console.error("Error ", error);
        setshowError(true);
        setLoading(false);
      }
    }
  };
  const handleRedirection = () => {
    const redirectUrl = localStorage.getItem("redirectAfterLogin") || "/";

    // Clear the redirect URL from localStorage
    localStorage.removeItem("redirectAfterLogin");

    // Redirect the user back to the full URL
    window.location.href = redirectUrl;
  };
  async function handleSuccess(response) {
    console.log(response);

    try {
      var result = await Post(URL_CONSTANTS.facebooklogin, {
        userId: response.authResponse.userID,
        accessToken: response.authResponse.accessToken,
      });

      const { token } = result.response;
      dispatch(setUserData(result.response));
      setLoading(false);
      handleRedirection();
      //navigate(userRoutes.dashboard);
      Cookies.set("ishnoorauthToken", token, { expires: 1 });
      toast.success("Logged in successfully");
      setTimeout(() => {
        fetchWishList();
        fetchCart();
      }, 1500);
    } catch (error) {
      console.log(error);
      setshowError(true);
      setLoading(false);
    }
  }

  function handleError(error) {
    console.log(error);
  }

  useEffect(() => {
    // Initialize Facebook SDK
    // window.fbAsyncInit = function () {
    //   window.FB.init({
    //     appId: "362205406980936", // Replace with your Facebook App ID
    //     cookie: true,
    //     xfbml: true,
    //     version: "v17.0",
    //     // Use the latest Facebook API version
    //   });
    // };
  }, []);

  const handleFBLogin = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          console.log("Welcome! Fetching your information.... ");
          window.FB.api(
            "/me",
            { fields: "id,name,email" },
            function (userInfo) {
              console.log("User Info:", userInfo);
              // Handle user information (e.g., send to your backend)
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email,public_profile" } // Request permissions
    );
  };

  return (
    <>
      {loading && <Loader />}
      <Box className="">
        <div className="login_screen">
          <div className="login">
            <Box>
              <Grid container padding={0} textAlign={"center"}>
                <Grid xs={12} className="mb-7">
                  <img
                    loading="eager"
                    onClick={() => navigate(userRoutes.dashboard)}
                    src="/images/logo.png"
                    alt="logo"
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
                <Grid xs={12} className="mb-2">
                  <h1 className="welcome">WELCOME</h1>
                </Grid>
                <Grid xs={12} className="mb-7">
                  <p className="welMsg">
                    Hey, Enter your details to get sign in to your account
                  </p>
                </Grid>

                {showError && (
                  <Grid xs={12} className="mb-7">
                    <p className="errormsg">{errorMessage}</p>
                  </Grid>
                )}
                <Grid xs={12} className="mb-3">
                  <SDTextField
                    title="Username/Email"
                    value={email}
                    attrName={setEmail}
                    value_update={(d, e) => setEmail(e)}
                    cls="w-100"
                    required
                  />
                </Grid>
                <Grid xs={12} className="mb-3">
                  <SDTextField
                    title="Password"
                    type="password"
                    value={password}
                    attrName={setPassword}
                    value_update={(d, e) => setPassword(e)}
                    cls="w-100"
                    required
                  />
                </Grid>
                <Grid xs={12} className="mb-3" textAlign={"end"}>
                  <h2
                    onClick={() => navigate(userRoutes.forgotPassword)}
                    className="forgot_pw"
                  >
                    Forgot Password?
                  </h2>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isTermsAccepted}
                        onChange={(e) => setIsTermsAccepted(e.target.checked)}
                        size="small" // Reduces the size of the checkbox
                        sx={{
                          color: "#8E282B", // Default color
                          "&.Mui-checked": {
                            color: "#8E282B", // Checked color
                          },
                        }}
                      />
                    }
                    label="I accept the Terms and Conditions"
                    sx={{
                      fontSize: "12px", // Smaller font size for the label
                      ".MuiTypography-root": { fontSize: "12px" }, // Targets label text specifically
                      alignItems: "center", // Aligns checkbox and label properly
                    }}
                  />
                </Grid>
                <Grid xs={12} className="ctaBtn mb-6">
                  <Button className="submitBtn w-100" onClick={handleLogin}>
                    Login
                  </Button>
                </Grid>
                <Grid container justifyContent="center" spacing={2}>
                  {/* Google Login */}
                  <Grid item>
                    <GoogleOAuthProvider clientId="828839738431-bh2atf79krju796t9gaopsgsentm9rhl.apps.googleusercontent.com">
                      <GoogleLogin
                        onSuccess={googleResponse}
                        onError={() => console.log("Google Login Failed")}
                        className="google-login"
                        style={{ display: "none" }}
                      />
                    </GoogleOAuthProvider>
                  </Grid>

                  {/* Facebook Login */}
                  <Grid item>
                    <FacebookProvider appId={"362205406980936"}>
                      <Button
                        variant="contained"
                        startIcon={<FacebookIcon />} // Add the MUI Facebook icon here
                        sx={{
                          backgroundColor: "#4267b2", // Facebook blue
                          color: "#fff", // White text
                          "&:hover": {
                            backgroundColor: "#365899", // Darker Facebook blue on hover
                          },
                          padding: "10px 20px",
                          borderRadius: "5px",
                          textTransform: "none", // Avoid all-uppercase text
                          fontWeight: "bold",
                        }}
                      >
                        <LoginButton
                          scope="email"
                          onError={handleError}
                          onSuccess={handleSuccess}
                          style={{
                            color: "inherit",
                            background: "none",
                            border: "none",
                            fontSize: "inherit",
                          }}
                        >
                          Sign in with Facebook
                        </LoginButton>
                      </Button>
                    </FacebookProvider>
                  </Grid>
                </Grid>

                <Grid xs={12} className="mb-0 mt-3">
                  <p className="forgot">
                    Don't have an account?{" "}
                    <span onClick={() => navigate(userRoutes.signUp)}>
                      Sign Up
                    </span>
                  </p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Login;
