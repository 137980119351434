import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import toast from "react-hot-toast";
import { Post } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import TextArea from "../Supporting files/TextArea/TextArea";
import {
  validateEmail,
  validatePhone,
} from "../Supporting files/HelpingFunction";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });

  // Handle onChange for form fields
  const handleInputChange = (attrName, value) => {
    setFormData({ ...formData, [attrName]: value });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {
      name: formData.name === "",
      email: !validateEmail(formData.email),
      phone: !validatePhone(formData.phone),
      message: formData.message === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };

  // Handle form submit
  const handleSubmit = async () => {
    if (validateForm()) {
      let payload = {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phone,
        message: formData.message,
      };

      try {
        await Post(URL_CONSTANTS.contactUs, payload);
        toast.success("Message sent successfully!");
        // Reset form after successful submission
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        window.fbq("track", "Contact");
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred while sending the message.");
      }
    }
  };

  return (
    <>
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="our_blogs contact_page">
          <Box>
            <h1>Contact Us</h1>
            <h2>
              Home/<span> Contact Us</span>
            </h2>
          </Box>
          <Box className="contactSection">
            <Grid container padding={0}>
              {/* <Grid xs={12} className="imgPart mb-20">
                <img
                  loading="eager"
                  className="abtImg w-100"
                  src="images/abtus.svg"
                  alt="about"
                />
                <h2>We would love to hear from you</h2>
              </Grid> */}
              <Grid xs={12} className="touch">
                <Box>
                  <Grid container padding={0}>
                    {/* <Grid xs={12} className="touchHead mb-6">
                      <h2 className="h2">Get In touch</h2>
                    </Grid> */}
                    <Grid xs={12} lg={6} className="touchimg">
                      <img
                        loading="eager"
                        className="w-100"
                        src="images/getintouch.svg"
                      />
                    </Grid>
                    <Grid xs={12} lg={6} className="touchForm">
                      <Box>
                        <Grid container spacing={3}>
                          <Grid xs={12} md={6}>
                            <SDTextField
                              title="Name"
                              value={formData.name}
                              attrName="name"
                              value_update={handleInputChange}
                              cls="w-100"
                              warn_status={errors.name}
                              helperText={errors.name && "Name is required"}
                              required
                              validationtype={"name"}
                            />
                          </Grid>
                          <Grid xs={12} md={6}>
                            <SDTextField
                              title="Email"
                              value={formData.email}
                              attrName="email"
                              value_update={handleInputChange}
                              cls="w-100"
                              warn_status={errors.email}
                              helperText={
                                formData.email
                                  ? "Enter valid email"
                                  : "Email is required"
                              }
                              required
                            />
                          </Grid>
                          <Grid xs={12}>
                            <SDTextField
                              title="Phone Number"
                              value={formData.phone}
                              attrName="phone"
                              value_update={handleInputChange}
                              cls="w-100"
                              warn_status={errors.phone}
                              helperText={
                                formData.phone
                                  ? "Enter valid Phone number"
                                  : "Phone number is required"
                              }
                              required
                              validationtype={"number"}
                              maxLimit={10}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <TextArea
                              title={"Message"}
                              value={formData.message}
                              attrName="message"
                              value_update={handleInputChange}
                              warn_status={errors.message}
                              class_name={"inputTextfield"}
                              placeholder="Message"
                              helperText={
                                errors.message && "Message is required"
                              }
                              required
                            />
                          </Grid>
                          <Grid xs={12}>
                            <h5 className="msg">
                              We’d love to hear from you! You can reach us at
                              support@ishnoor.in or WhatsApp us at +91
                              8175918027. Our team will respond within 24-48
                              hours.
                            </h5>
                          </Grid>
                          <Grid xs={12}>
                            <h2 className="msg">
                              Address: <br /> Ishnoor <br />
                              645B/P-283/1 Vasisthapuram Jankipuram,
                              <br />
                              Lucknow – 226031
                            </h2>
                          </Grid>
                          <Grid xs={12} className="ctaBtn text-left">
                            <Button
                              variant="contained"
                              className="submitBtn"
                              onClick={handleSubmit}
                            >
                              Send
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  );
};

export default ContactUs;
