import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get, Post, Put } from "../../Api/api";
import { URL_CONSTANTS } from "../../Api/ApiUrl";
import {
  updateCart,
  updateCartAmount,
  updateWishlist,
} from "../../Redux/Slice/UserSlice";
import Loader from "../Loader/Loader";
import { userRoutes } from "../../Routes/UserRoutes";
import { formatCurrency } from "../../Supporting files/HelpingFunction";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const CartSidebar = ({ open, onClose }) => {
  const theme = useTheme();
  const wishlistData = useSelector((state) => state.user.wishlist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (open) fetchCart();
  }, [open]);

  const fetchCart = async () => {
    setIsLoader(true);
    try {
      const response = await Get(`${URL_CONSTANTS.getCartItems}`);
      const products = response?.response?.products || [];
      setCartItems(products);
      setIsLoader(false);
      dispatch(updateCart(response?.response));
      dispatch(
        updateCartAmount(
          products.reduce(
            (total, data) => total + +data.productDetail.variants.sizes.price,
            0
          )
        )
      );
    } catch (error) {
      setCartItems([]);
      dispatch(updateCart([]));
      setIsLoader(false);
    }
  };

  const removeFromCart = async (productId, variantId, sizeId) => {
    const dto = { productId, variantId, sizeId, quantity: 1 };
    try {
      const response = await Put(
        `${URL_CONSTANTS.removeCartItems}`,
        JSON.stringify(dto)
      );
      toast.success("Item removed from cart.");
      dispatch(updateCart(response.response));
      fetchCart();
    } catch (error) {
      console.error(error);
    }
  };

  const moveToWishlist = async (productId, variantId, sizeId) => {
    const dto = { productId, variantId, sizeId };
    try {
      const response = await Put(
        `${URL_CONSTANTS.moveToWishList}`,
        JSON.stringify(dto)
      );
      toast.success("Moved to wishlist.");
      fetchCart();
      dispatch(updateWishlist(response.response.products));
    } catch (error) {
      console.error(error);
    }
  };

  const updateQuantity = async (productId, variantId, sizeId, quantity) => {
    const dto = { productId, variantId, sizeId, quantity };
    try {
      await Post(`${URL_CONSTANTS.AddCartItems}`, JSON.stringify(dto));
      fetchCart();
      toast.success("Quantity updated.");
    } catch (error) {
      console.error(error);
    }
  };
  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.productDetail.variants.sizes.mrp * item.quantity,
    0
  );

  const discount = cartItems.reduce(
    (acc, item) =>
      acc +
      (item.productDetail.variants.sizes.mrp -
        item.productDetail.variants.sizes.price) *
        item.quantity,
    0
  );

  const total = subtotal - discount;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile screens

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "90%", sm: 500 }, // Responsive width
          padding: 2,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {isLoader && <Loader />}

      {/* Header with Close Icon */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">My Cart</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Scrollable Content */}
      <Box
        mt={2}
        sx={{
          maxHeight: "calc(100vh - 220px)", // Adjust height for footer section
          overflowY: "auto",
        }}
      >
        {cartItems.length > 0 ? (
          <Grid container spacing={2}>
            {cartItems.map((item) => {
              const maxQuantity =
                item?.productDetail?.variants?.sizes?.quantity || 1;
              const options = Array.from(
                { length: Math.min(maxQuantity, 6) },
                (_, i) => i + 1
              );
              const isWishlistExist = wishlistData?.some(
                (product) =>
                  product.sizeId === item?.productDetail?.variants?.sizes?._id
              );
              return (
                <Grid item xs={12} key={item.id}>
                  <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: 1.5,
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    }}
                  >
                    {/* Image */}
                    <img
                      src={item?.productDetail?.variants?.images[0]}
                      alt="Product"
                      style={{
                        // width: 80,
                        height: 90,
                        objectFit: "contain",
                        cursor: "pointer",
                        borderRadius: "8px",
                        // backgroundColor: "#f8f8f8",
                      }}
                      onClick={() => {
                        onClose();
                        navigate(
                          `${userRoutes.productDetail}#productId=${item.productId}`
                        );
                      }}
                    />
                    <Box flex={1}>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{
                          lineHeight:"unset",
                          marginBottom:1,
                        }}
                      >
                        {item?.productDetail?.title}
                      </Typography>

                      <Box display="flex" flexWrap="wrap" alignItems="center" rowGap={1} columnGap={2} mb={1}>
                        {/* Price */}
                        <Typography variant="body2" color="textSecondary">
                          {`Price: ${formatCurrency(
                            item?.productDetail?.variants?.sizes?.price
                          )}`}
                        </Typography>

                        {/* Color */}
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          display="flex"
                          alignItems="center"
                        >
                          Color:
                          <Box
                            component="span"
                            sx={{
                              display: "inline-block",
                              width: 16,
                              height: 16,
                              borderRadius: "50%",
                              backgroundColor:
                                item?.productDetail?.variants?.color,
                              marginLeft: 1,
                              border: "1px solid #ddd",
                            }}
                          />
                        </Typography>

                        {/* Size */}
                        <Typography variant="body2" color="textSecondary">
                          {`Size: ${item?.productDetail?.variants?.sizes?.size}`}
                        </Typography>
                      </Box>
                      {/* Dropdown for Quantity */}
                      <Box display="flex" alignItems="center" gap={2}>
                        <FormControl sx={{ minWidth: 80 }}>
                          <Select
                            size="small"
                            value={item.quantity}
                            onChange={(e) =>
                              updateQuantity(
                                item.productId,
                                item.variantId,
                                item.sizeId,
                                e.target.value
                              )
                            }
                          >
                            {options.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* Icons for Remove and Move to Wishlist */}
                        <Box display="flex" gap={1}>
                          <IconButton
                            size="small"
                            onClick={() =>
                              removeFromCart(
                                item.productId,
                                item.variantId,
                                item.sizeId
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (isWishlistExist) {
                                toast.error("Already in wishlist");
                              } else {
                                moveToWishlist(
                                  item.productId,
                                  item.variantId,
                                  item.sizeId
                                );
                              }
                            }}
                          >
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Box textAlign="center" mt={4}>
            <ShoppingCartOutlinedIcon fontSize="large" />
            <Typography variant="h6">Your Cart is Empty</Typography>
            <Typography color="textSecondary">
              Add items to your cart to see them here.
            </Typography>
          </Box>
        )}
      </Box>

      {/* Fixed Footer with Place Order Button */}
      {cartItems.length > 0 && (
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "#fff",
            padding: 2,
            borderTop: "1px solid #ddd",
          }}
        >
          {/* Subtotal, Discount, and Total */}
          <Box mb={2}>
            {/* Subtotal */}
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="body1" fontWeight="bold">
                Subtotal:
              </Typography>
              <Typography variant="body1">
                {formatCurrency(subtotal)}
              </Typography>
            </Box>

            {/* Discount */}
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="textSecondary"
              >
                Discount:
              </Typography>
              <Typography variant="body1" sx={{ color: "green" }}>
                - {formatCurrency(discount)}
              </Typography>
            </Box>

            {/* Total */}
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight="bold">
                Total:
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                {formatCurrency(total)}
              </Typography>
            </Box>
          </Box>

          {/* Checkout Button */}
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#8e282b",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#731e22",
              },
            }}
            onClick={() => {
              const isOutOfStock = cartItems.some(
                (item) =>
                  Number(item.productDetail.variants.sizes.quantity) < 1 ||
                  !item.productDetail.isActive
              );

              if (isOutOfStock) {
                toast.error("Some of the products are out of stock");
              } else {
                onClose();
                navigate(userRoutes.checkout);
              }
            }}
          >
            Checkout
          </Button>
        </Box>
      )}
    </Drawer>
  );
};

export default CartSidebar;
