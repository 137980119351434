import { Box, Button, capitalize, Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Post } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import Loader from "../components/Loader/Loader";
import { userRoutes } from "../Routes/UserRoutes";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import toast from "react-hot-toast";
import {
  validateEmail,
  validatePhone,
} from "../Supporting files/HelpingFunction";

const SignUp = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
  });
  const [formValuesWarn, setFormValuesWarn] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    phone: false,
  });
  const [loading, setLoading] = useState(false);
  const [otpScreen, setotpScreen] = useState(false);
  const [otp, setotp] = useState("");

  const handleChange = (attrName, value) => {
    setFormValues((prev) => ({
      ...prev,
      [attrName]: value,
    }));
    setFormValuesWarn((prev) => ({
      ...prev,
      [attrName]: !!!value,
    }));
  };

  const validateForm = () => {
    const { firstName, lastName, email, password, phone } = formValues;
    setFormValuesWarn({
      firstName: !!!firstName,
      lastName: !!!lastName,
      email: !validateEmail(email),
      password: !!!password,
      phone: !validatePhone(phone),
    });
    if (
      !firstName ||
      !lastName ||
      !validateEmail(email) ||
      !password ||
      !validatePhone(phone)
    ) {
      return false;
    }
    return true;
  };

  const handleSignup = async () => {
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    try {
      const response = await Post(
        `${URL_CONSTANTS.signUp}`,
        JSON.stringify(formValues)
      );
      setotpScreen(true);
      toast.success(capitalize(response.message));
      window.fbq("track", "CompleteRegistration");
      //navigate(userRoutes.login);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    if (!otp) {
      toast.error("Please enter otp");
      return;
    }
    try {
      const response = await Post(
        `${URL_CONSTANTS.verifyotp}`,
        JSON.stringify({ email: formValues.email, otp })
      );
      toast.success(capitalize(response.message));
      navigate(userRoutes.login);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Box className="">
        {/* <Box className="mainWrapper"> */}
        <div className="login_screen">
          <div className="login">
            <Box>
              <Grid container padding={0} textAlign={"center"}>
                <Grid xs={12} className="mb-7">
                  <img
                    loading="eager"
                    onClick={() => navigate(userRoutes.dashboard)}
                    src="/images/logo.png"
                    alt="logo"
                  />
                </Grid>
                <Grid xs={12} className="mb-7">
                  <h1 className="welcome">
                    {otpScreen ? "Enter Your OTP" : "Create Your Account"}
                  </h1>
                </Grid>
                {otpScreen ? (
                  <Grid xs={12} className="mb-6">
                    <SDTextField
                      title="Enter Otp"
                      value={otp}
                      attrName="otp"
                      value_update={(a, v) => {
                        setotp(v);
                      }}
                      cls="w-100"
                      required={true}
                      validationtype={"number"}
                      maxLimit={6}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid xs={12} className="mb-6">
                      <SDTextField
                        title="First name"
                        value={formValues.firstName}
                        attrName="firstName"
                        value_update={handleChange}
                        cls="w-100"
                        required={true}
                        warn_status={formValuesWarn.firstName}
                        validationtype="name"
                      />
                    </Grid>
                    <Grid xs={12} className="mb-6">
                      <SDTextField
                        title="Last name"
                        value={formValues.lastName}
                        attrName="lastName"
                        value_update={handleChange}
                        cls="w-100"
                        required={true}
                        warn_status={formValuesWarn.lastName}
                        validationtype="name"
                      />
                    </Grid>
                    <Grid xs={12} className="mb-6">
                      <SDTextField
                        title="Email"
                        type="email"
                        value={formValues.email}
                        attrName="email"
                        value_update={handleChange}
                        cls="w-100"
                        required={true}
                        warn_status={formValuesWarn.email}
                        helperText={
                          formValues.email.length > 0
                            ? "Enter valid email"
                            : "Enter email"
                        }
                      />
                    </Grid>
                    <Grid xs={12} className="mb-6">
                      <SDTextField
                        title="Phone"
                        type="phone"
                        value={formValues.phone}
                        attrName="phone"
                        value_update={handleChange}
                        cls="w-100"
                        required={true}
                        validationtype={"number"}
                        warn_status={formValuesWarn.phone}
                        maxLimit={10}
                        helperText={
                          formValues.phone.length > 0
                            ? "Enter valid phone"
                            : "Enter Phone"
                        }
                      />
                    </Grid>
                    <Grid xs={12} className="mb-6">
                      <SDTextField
                        title="Password"
                        type="password"
                        value={formValues.password}
                        attrName="password"
                        value_update={handleChange}
                        cls="w-100"
                        required={true}
                        warn_status={formValuesWarn.password}
                      />
                    </Grid>
                  </>
                )}
                <Grid xs={12} className="ctaBtn mb-6">
                  <Button
                    className="submitBtn w-100"
                    onClick={() => {
                      otpScreen ? verifyOtp() : handleSignup();
                    }}
                  >
                    Sign Up
                  </Button>
                </Grid>
                <Grid xs={12} className="mb-0">
                  <p className="forgot">
                    Already have an account?{" "}
                    <span onClick={() => navigate(userRoutes.login)}>
                      Login
                    </span>
                  </p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default SignUp;
