import {
  Box,
  Container,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import "../Admin/admin.scss";
import { Get, Patch } from "../Api/api";
import CustomTable from "../Supporting files/CustomTable/CustomTable";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
import { DeliveryStatus, ReturnStatus } from "../Supporting files/Enum";
const AllReturnRequest = () => {
  const [totalCount, setTotalCount] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [tableLimit, setTableLimit] = useState();
  const orderStatusOptions = [
    { value: ReturnStatus.Requested, label: "Requested" },
    { value: ReturnStatus.Accepted, label: "Accepted" },
    { value: ReturnStatus.Rejected, label: "Rejected" },
    { value: ReturnStatus.Completed, label: "Completed" },
  ];
  const columns = [
    {
      field: "orderId",
      headerName: "Order ID",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "orderNumber",
      headerName: "Order Number",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Update Order Status",
      flex: 3,
      minWidth: 200,
      check_status: true,
      renderCell: (params) => {
        let { row } = params;
        const handleStatusChange = (e) => {
          const newStatus = e.target.value;
          updateOrderstatus(row.id, newStatus);
        };

        return (
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={row.status || ""} // Ensure a valid default value
              onChange={(e) => handleStatusChange(e, row)}
              label="Status"
              sx={{
                width: "100%", // Ensure dropdown fits within the cell
                height: 40, // Adjust height for visibility
                fontSize: 14, // Smaller font to fit better
              }}
            >
              {orderStatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "deliveryAddress",
      headerName: "Delivery Address",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.deliveryAddress.name}, ${params.row.deliveryAddress.address}, ${params.row.deliveryAddress.city}, ${params.row.deliveryAddress.district}, ${params.row.deliveryAddress.pincode}`,
    },
    {
      field: "productDetails",
      headerName: "Product Details",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        params.row.productDetails
          .map(
            (product) =>
              `Product ID: ${product.productId}, Variant ID: ${product.variantId}, Size ID: ${product.sizeId}, Quantity: ${product.quantity}, MRP: ₹${product.mrp}, Price: ₹${product.price}`
          )
          .join("\n"),
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "deliveryStatus",
      headerName: "Delivery Status",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "deliveryTime",
      headerName: "Delivery Time",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) =>
        new Date(params.row.deliveryTime).toLocaleString(), // Format delivery time
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => new Date(params.row.createdAt).toLocaleString(), // Format order date
    },
  ];

  const [body, setBody] = useState([]);
  useEffect(() => {
    getReturnRequest();
  }, []);
  const updateMasterState = () => {};
  const getReturnRequest = async (page = 0, pageSize = 25) => {
    try {
      const response = await Get(
        `${ADMINURL_CONSTANTS.returnRequest}?page=${
          page + 1
        }&pageSize=${pageSize}`
      );
      setTotalCount(response.count);
      const rows = response.response.map((item, index) => ({
        id: item._id || index, // Unique ID for the DataGrid
        orderId: item.orderDetail._id,
        orderNumber: item.orderDetail.orderNumber,
        status: item.status,
        userName: `${item.userDetail.firstName} ${item.userDetail.lastName}`,
        email: item.userDetail.email,
        phone: item.userDetail.phone,
        deliveryAddress: {
          name: item.orderDetail.deliveryAddress.name,
          address: item.orderDetail.deliveryAddress.address,
          city: item.orderDetail.deliveryAddress.city,
          district: item.orderDetail.deliveryAddress.district,
          pincode: item.orderDetail.deliveryAddress.pincode,
        },
        productDetails: item.product,
        totalAmount: item.orderDetail.totalAmount,
        paymentMode: item.orderDetail.paymentMode,
        deliveryStatus: item.orderDetail.deliveryStatus,
        deliveryTime: item.orderDetail.deliveryTime,
        createdAt: item.orderDetail.createdAt,
      }));

      setBody(rows);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

    const updateOrderstatus = async (id, status) => {
      let dto = {
        status,
      };
      try {
        const response = await Patch(
          `${ADMINURL_CONSTANTS.updateReturntatus}${id}`,
          dto
        );
        getReturnRequest();
      } catch (error) {
        console.error("Error ", error.message);
      }
    };
  return (
    <Container className="adminWrapper">
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          All Return Request
        </Typography>
        <CustomTable
          header_data={columns}
          data={body}
          value_update={updateMasterState}
          attrName={"UGTable"}
          BE_filter_code={20}
          totalRecords={totalCount}
          refetch_data={getReturnRequest}
          setTableLimit={setTableLimit}
          setTableSkip={setTableSkip}
        />
      </Box>
    </Container>
  );
};

export default AllReturnRequest;
