import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Check from "@mui/icons-material/Check";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import Stepper from "@mui/joy/Stepper";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Get } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import Footer from "../components/Footer";
import Loader from "../components/Loader/Loader";
import ReviewModal from "../components/Modals/ReviewModal";
import { updateReturnOrderNumber } from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import { canReturnOrder, formatOrderStatus } from "../Supporting files/HelpingFunction";

const OrderDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const steps = [
    { name: "Order Placed", img: "images/Package.png", value: "order_placed" },
    { name: "Packaging", img: "images/Notebook.png", value: "packaging" },
    { name: "On The Road", img: "images/Package.png", value: "on_the_road" },
    { name: "Delivered", img: "images/Notebook.png", value: "delivered" },
  ];
  const steps1 = [
    {
      name: "Order Cancelled",
      img: "images/Package.png",
      value: "order_cancelled",
    },
    { name: "Packaging", img: "images/Notebook.png", value: "packaging" },
    { name: "On The Road", img: "images/Package.png", value: "on_the_road" },
    { name: "Delivered", img: "images/Notebook.png", value: "delivered" },
  ];
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [orderDetails, setorderDetails] = useState([]);
  const [reviewModal, setReviewModal] = useState(false);
  const [selectedProduct, setselectedProduct] = useState("");
  const [editReviewData, seteditReviewData] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [value, setValue] = useState([]);

  useEffect(() => {
    getOrderDetails();
  }, []);
  const getOrderDetails = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.OrdersDetails}${id}`);
      setorderDetails(response.response);
      const valueToFind = response?.response?.deliveryStatus;

      if (valueToFind === "order_cancelled") {
        setValue(steps1);
        setActiveStep(1);
        setLoading(false);
      } else {
        setValue(steps);
        const index = steps.findIndex((step) => step.value === valueToFind);
        setActiveStep(index + 1);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error ", error.message);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {reviewModal && (
        <ReviewModal
          open={reviewModal}
          onClose={() => setReviewModal(false)}
          orderData={orderDetails}
          selectedProduct={selectedProduct}
          isEdit={isEdit}
          editReviewData={editReviewData}
          onSuccess={getOrderDetails}
        />
      )}
      <Box className="mainWrapper">
        <Box className="orderDetailSection">
          <Grid container padding={0}>
            <Grid xs={12}>
              <div className="title">
                <ArrowBackIcon
                  onClick={() => {
                    navigate(userRoutes.orders);
                  }}
                />
                <h2>Order Details</h2>
              </div>
            </Grid>
            <Grid xs={12} className="pro_detail">
              <div className="left_order" style={{ width: "80%" }}>
                <h3>Order ID : #{orderDetails.orderNumber}</h3>
                <h3 className="des">
                  {orderDetails?.products?.length > 0 &&
                    orderDetails?.products[0]?.productDetail?.title}
                </h3>
                <h3 className="des_light">
                  {orderDetails?.products?.length}{" "}
                  {orderDetails?.products?.length === 1
                    ? "Product"
                    : "Products"}
                  <span className="mx-2">•</span>
                  Order Placed on{" "}
                  {moment(new Date(orderDetails?.createdAt)).format(
                    "DD MMM, YYYY [at] h:mm A"
                  )}
                </h3>
              </div>
              <div>
                <h1>₹ {orderDetails?.totalAmount}</h1>
              </div>
            </Grid>
            <Grid xs={12} className="expected mt-6">
              {orderDetails.deliveryStatus === "delivered" ? (
                <p className="date mb-6 fw-600">
                  Order Delivered On{" "}
                  {moment(orderDetails?.deliveryTime).format("DD MMM, YYYY")}
                </p>
              ) : orderDetails.deliveryStatus === "order_cancelled" ? (
                ""
              ) : (
                <p className="date mb-6 fw-600">
                  Order Expected Arrival{" "}
                  {moment(orderDetails?.createdAt)
                    .add(6, "days")
                    .format("DD MMM, YYYY")}
                </p>
              )}

              <div class="stepper_section">
                {orderDetails.deliveryStatus === "order_cancelled" ? (
                  <Stepper sx={{ width: "100%" }}>
                    {[
                      {
                        name: "Order Cancelled",
                        img: "images/Package.png",
                        value: "order_cancelled",
                      },
                    ]?.map((step, i) => (
                      <Step
                        className={`${
                          activeStep >= i + 1 ? "active_step" : "inactive_step"
                        }`}
                        orientation="vertical"
                        indicator={
                          <StepIndicator variant="solid" color="neutral">
                            {activeStep >= i + 1 ? <Check /> : ""}
                          </StepIndicator>
                        }
                      >
                        <img
                          loading="eager"
                          className="mb-1 step_images"
                          src={step.img}
                          alt="ordr"
                        />
                        {step.name}
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  <Stepper sx={{ width: "100%" }}>
                    {value?.map((step, i) => (
                      <Step
                        className={`${
                          activeStep >= i + 1 ? "active_step" : "inactive_step"
                        }`}
                        orientation="vertical"
                        indicator={
                          <StepIndicator variant="solid" color="neutral">
                            {activeStep >= i + 1 ? <Check /> : ""}
                          </StepIndicator>
                        }
                      >
                        <img
                          loading="eager"
                          className="mb-1 step_images"
                          src={step.img}
                          alt="ordr"
                        />
                        {step.name}
                      </Step>
                    ))}
                  </Stepper>
                )}
              </div>
            </Grid>
            <Grid xs={12} className="product_table mt-12">
              <p className="count mb-5">
                Product ({orderDetails?.products?.length})
              </p>
              {orderDetails?.products?.length > 0 &&
                orderDetails?.products?.map((product, i) => {
                  return (
                    <>
                      <Box className="mob_ul_list mb-2" key={i}>
                        <img
                          className="pro_img"
                          src={product?.productDetail?.variants?.images[0]}
                          alt="Product"
                          onClick={() => {
                            navigate(
                              `${userRoutes.productDetail}#productId=${product?.productId}`
                            );
                          }}
                        />
                        <Box>
                          <p
                            className="titlee"
                            onClick={() => {
                              navigate(
                                `${userRoutes.productDetail}#productId=${product?.productId}`
                              );
                            }}
                          >
                            {" "}
                            {product?.productDetail?.title}
                          </p>
                          <Box className="btm_box">
                            <Typography variant="body2" color="#6d5e5e">
                              {`Size: ${product?.productDetail?.variants?.sizes?.size}`}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="#6d5e5e"
                              display="flex"
                              alignItems="center"
                            >
                              Color:
                              <Box
                                component="span"
                                sx={{
                                  display: "inline-block",
                                  width: 16,
                                  height: 16,
                                  borderRadius: "50%",
                                  backgroundColor:
                                    product?.productDetail?.variants?.color,
                                  marginLeft: 1,
                                  border: "1px solid #ddd",
                                }}
                              />
                            </Typography>
                            <Typography variant="body2" color="#6d5e5e">
                              {`Price: ₹ ${product?.price}`}
                            </Typography>
                            <Typography variant="body2" color="#6d5e5e">
                              {`Qty: ${product?.quantity}`}
                            </Typography>
                            <Typography variant="body2" color="#6d5e5e">
                              {`Total: ₹ ${product?.quantity * product?.price}`}
                            </Typography>
                          </Box>
                          <Box className="btm_box">
                            <Typography variant="body2" color="#6d5e5e">
                              <h4
                                onClick={() => {
                                  if (orderDetails.deliveryTime) {
                                    setReviewModal(true);
                                    setselectedProduct(product);
                                  }
                                  if (product?.review?.comment) {
                                    seteditReviewData(product.review);
                                    setisEdit(true);
                                  }
                                }}
                                className="pro_title mb-1 mt-2"
                                style={{ cursor: "pointer", fontWeight: 400 }}
                              >
                                {product?.review?.comment
                                  ? "Edit Review"
                                  : orderDetails.deliveryTime
                                  ? "Write Review"
                                  : ""}
                              </h4>
                            </Typography>
                            {!product?.returnOrder?.returnStatus &&
                              canReturnOrder(orderDetails.deliveryTime) && (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <h4
                                    onClick={() => {
                                      dispatch(
                                        updateReturnOrderNumber({
                                          orderNumber: orderDetails.orderNumber,
                                          productId: product.productId,
                                          _id: product._id,
                                          name: product.productDetail.title,
                                          orderId: orderDetails._id,
                                        })
                                      );
                                      navigate(userRoutes.returnRequest);
                                    }}
                                    className="pro_title mb-1 mt-2"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {"Raise Return Request"}
                                  </h4>
                                </Typography>
                              )}
                            {product?.returnOrder?.returnStatus && (
                              <Typography variant="body2" color="textSecondary">
                                <h4
                                  className="pro_title mb-1 mt-2"
                                  style={{
                                    
                                    fontWeight: 400,
                                  }}
                                >
                                  Return/Exchange Status:{" "}
                                  {formatOrderStatus(
                                    product?.returnOrder?.returnStatus
                                  )}
                                </h4>
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  );
                })}

              <ul className="ul_header">
                <li>PRODUCTS</li>
                <li>SIZE</li>
                <li>COLOR</li>
                <li>PRICE</li>
                <li>QTY</li>
                <li>TOTAL</li>
              </ul>
              {orderDetails?.products?.length > 0 &&
                orderDetails?.products?.map((product, index) => {
                  return (
                    <>
                      <ul className="ul_body">
                        <li>
                          <img
                            loading="eager"
                            src={product?.productDetail?.variants?.images[0]}
                            style={{ cursor: "pointer" }}
                            alt="ii"
                            width={60}
                            onClick={() => {
                              navigate(
                                `${userRoutes.productDetail}#productId=${product?.productId}`
                              );
                            }}
                          />
                          <div className="body_div">
                            <h4
                              className="pro_title mb-1 mt-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(
                                  `${userRoutes.productDetail}#productId=${product?.productId}`
                                );
                              }}
                            >
                              {product?.productDetail?.title}
                            </h4>
                            {product?.returnOrder?.returnStatus && (
                              <h4
                                className="pro_title"
                                style={{ fontWeight: 400 }}
                              >
                                Return/Exchange Status:{" "}
                                <span>
                                  {formatOrderStatus(
                                    product?.returnOrder?.returnStatus
                                  )}
                                </span>
                              </h4>
                            )}
                            <h4
                              onClick={() => {
                                if (orderDetails.deliveryTime) {
                                  setReviewModal(true);
                                  setselectedProduct(product);
                                }

                                if (product?.review?.comment) {
                                  seteditReviewData(product.review);
                                  setisEdit(true);
                                }
                              }}
                              className="pro_title mb-1 mt-2"
                              style={{ cursor: "pointer", fontWeight: 400 }}
                            >
                              {product?.review?.comment
                                ? "Edit Review"
                                : orderDetails.deliveryTime
                                ? "Write Review"
                                : ""}
                            </h4>
                            {true &&
                              canReturnOrder(orderDetails.deliveryTime) && (
                                <h4
                                  onClick={() => {
                                    dispatch(
                                      updateReturnOrderNumber({
                                        orderNumber: orderDetails.orderNumber,
                                        productId: product.productId,
                                        _id: product._id,
                                        name: product.productDetail.title,
                                        orderId: orderDetails._id,
                                      })
                                    );
                                    navigate(userRoutes.returnRequest);
                                  }}
                                  className="pro_title mb-1 mt-3"
                                  style={{ cursor: "pointer", fontWeight: 400 }}
                                >
                                  {"Raise Return Request"}
                                </h4>
                              )}
                          </div>
                        </li>
                        <li>{product?.productDetail?.variants?.sizes?.size}</li>
                        <li>
                          <div
                            style={{
                              background:
                                product?.productDetail?.variants?.color,
                            }}
                            className="colorss"
                          ></div>
                        </li>
                        <li>₹ {product?.price}</li>
                        <li>{product?.quantity}</li>
                        <li style={{ minWidth: "55px" }}>
                          ₹ {product?.quantity * product?.price}
                        </li>
                      </ul>
                      <div className="seprate"></div>
                    </>
                  );
                })}
            </Grid>
            <Grid xs={12} className="shipping">
              <Box className="w-100">
                <Grid container>
                  <Grid lg={3} className="ship">
                    <p
                      className="line1 mb-3"
                      style={{ fontSize: "16px", fontWeight: 900 }}
                    >
                      Billing Address:
                    </p>
                    <p className="line2 mb-2">
                      {orderDetails?.deliveryAddress?.name}
                    </p>
                    <p className="line2 mb-2">
                      {orderDetails?.deliveryAddress?.address} ,{" "}
                      {orderDetails?.deliveryAddress?.city} ,{" "}
                      {orderDetails?.deliveryAddress?.district},{" "}
                      {orderDetails?.deliveryAddress?.pincode}
                    </p>
                    <p className="line2 mb-2">
                      Phone Number: {orderDetails?.deliveryAddress?.phone}
                    </p>
                    {/* <p className="line2">Email: kevin.gilbert@gmail.com</p> */}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default OrderDetail;
