import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Unstable_Grid2";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import { transformProducts } from "../Supporting files/HelpingFunction";
import Footer from "../components/Footer";
import Loader from "../components/Loader/Loader";
import ProductCard from "../components/ProductCard";
import ProductsList from "../components/ProductsList";
import Chip from "@mui/material/Chip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { getColorNameByCode } from "./../Supporting files/HelpingFunction";
const ProductsData = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get("categoryId");
  const [value, setValue] = useState([0, 5000]); // Price filter
  const [productStats, setProductStats] = useState([]); // Stats like categories, colors, max price
  const [checkedCategories, setCheckedCategories] = useState(
    categoryId ? [categoryId] : []
  ); // Initialize with categoryId
  const [selectedColors, setSelectedColors] = useState([]); // Color filter
  const [allProducts, setAllProducts] = useState([]); // Products to display
  const [isLoader, setIsLoader] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // To avoid triggering API on first render
  const [openFilter, setOpenFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' for low to high, 'desc' for high to low
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef();

  const toggleSortOrder = () => {
    const newOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newOrder);
    sortProducts(newOrder); // Sort the products based on the new order
  };
  const toggleDrawer = () => setOpenFilter(!openFilter);
  // Handle category checkbox change
  const handleCheckboxChange = (id) => {
    setCheckedCategories((prevCheckedCategories) =>
      prevCheckedCategories.includes(id)
        ? prevCheckedCategories.filter((categoryId) => categoryId !== id)
        : [...prevCheckedCategories, id]
    );
    setPage(1);
  };

  // Handle color selection
  const handleColorClick = (color) => {
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.includes(color)
        ? prevSelectedColors.filter((selectedColor) => selectedColor !== color)
        : [...prevSelectedColors, color]
    );
    setPage(1);
  };
  const sortProducts = (order) => {
    const sortedProducts = [...allProducts].sort((a, b) => {
      const priceA = parseFloat(a.variants[0].sizes[0].price);
      const priceB = parseFloat(b.variants[0].sizes[0].price);

      return order === "asc" ? priceA - priceB : priceB - priceA;
    });
    setAllProducts(sortedProducts);
  };
  // Handle price range change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch product stats (categories, colors, max price)
  const GetProductStates = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getProductState);
      setValue([0, response.response.maxPrice]); // Set price range
      setProductStats(response.response); // Set categories and colors
    } catch (error) {
      console.error("Error fetching product states:", error.message);
    }
  };

  // Fetch filtered products
  const GetAllProduct = async () => {
    try {
      //setIsLoader(true);
      const categories = checkedCategories.join(",");
      const colors = selectedColors.map(encodeURIComponent).join(",");
      const min = value[0];
      const max = value[1];
      let query = `?min=${min}&max=${max}&page=${page}&pageSize=${pageSize}`;

      if (categories) query += `&categoryId=${categories}`;
      if (colors) query += `&colors=${colors}`;

      const url = `${URL_CONSTANTS.getAllProducts}${query}`;
      const response = await Get(url);
      let individualProducts;

      if (response.response.products.length > 0) {
        individualProducts = transformProducts([
          ...allProducts,
          ...response.response.products,
        ]);
        const totalLoaded =
          (page - 1) * pageSize + response.response.products.length;
        setHasMore(totalLoaded < response.response.count);
        setLoadingMore(false);
      } else {
        setHasMore(false);
      }
      if (page === 1) {
        setAllProducts(response.response.products);
      } else {
        if (response.response.products.length > 0) {
          setAllProducts((prevProducts) => [
            ...prevProducts,
            ...response.response.products,
          ]);
        }
        return;
      }
      setIsLoader(false);
    } catch (error) {
      console.error("Error fetching products:", error.message);
      setIsLoader(false);
    }
  };

  // Fetch product stats on initial load
  useEffect(() => {
    GetProductStates();
  }, []);

  // Trigger API only after all filters are set
  useEffect(() => {
    if (isInitialLoad) {
      // Only skip if `categoryId` is processed
      setIsInitialLoad(false);
      return;
    }
    setPage(1); // Reset pagination
    setAllProducts([]); // Clear products to avoid showing old data
    const timer = setTimeout(() => {
      GetAllProduct();
    }, 300); // Add debounce for smoother UX

    return () => clearTimeout(timer);
  }, [checkedCategories, selectedColors, value]);

  // Initialize `checkedCategories` if `categoryId` exists in the URL
  useEffect(() => {
    window.scrollTo(0, 0);
    setPage(1); // Reset page
    setAllProducts([]); // Clear products to avoid old data
    if (categoryId && !checkedCategories.includes(categoryId)) {
      // setCheckedCategories((prev) => [...prev, categoryId]);
      setCheckedCategories((prev) => [categoryId]);
    }
  }, [categoryId]);

  const lastProductRef = useCallback(
    (node) => {
      if (loadingMore || !hasMore) return; // Prevent loading if already loading
      if (observer.current) observer.current.disconnect(); // Disconnect the previous observer

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            console.log("Intersection observed, loading more...");
            setLoadingMore(true);
            setPage((prevPage) => prevPage + 1); // Increment page for loading
          }
        },
        {
          root: null, // Default: viewport
          rootMargin: "100px", // Trigger before the element is fully in view
          threshold: 0.1, // Trigger when 10% of the element is visible
        }
      );

      if (node) observer.current.observe(node); // Observe the node
    },
    [loadingMore, hasMore] // Dependencies
  );

  // This effect will run when the page is updated to load the products for the current page.
  useEffect(() => {
    GetAllProduct(); // Fetch the products for the current page
  }, [page]);

  return (
    <>
      {isLoader && <Loader />}
      <Box className="mainWrapper">
        {/* <Header /> */}
        <Box className="">
          <Grid container padding={0} columnSpacing={2} rowSpacing={2}>
            <Grid xs={12} sm={12} md={8} lg={9} className="lookSection">
              <Box className="look">
                <>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      marginBottom={2}
                    >
                      {/* Left Side: Filter Button */}
                      <button
                        onClick={toggleDrawer}
                        className="filter_filter"
                        aria-label="Filter"
                      >
                        <FilterListIcon />
                        <Typography sx={{ ml: 1 }}>Filter</Typography>
                      </button>

                      {/* Right Side: Sort Button with Icon */}
                      <Box display="flex" alignItems="center">
                        <Typography sx={{ mr: 1 }}>Sort by:</Typography>
                        <IconButton
                          onClick={toggleSortOrder}
                          sx={{
                            color: "#8E282B",
                            "&:hover": {
                              backgroundColor: "#f9e3e3",
                            },
                            padding: 0, // Remove extra padding
                          }}
                          aria-label="Sort Price"
                        >
                          <Typography variant="body2" sx={{ mr: 1 }}>
                            Price
                          </Typography>
                          {/* Using longer arrow icons */}
                          {sortOrder === "asc" ? (
                            <ArrowDropDownIcon sx={{ fontSize: "40px" }} />
                          ) : (
                            <ArrowDropUpIcon sx={{ fontSize: "40px" }} />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>

                  {checkedCategories.length > 0 || selectedColors.length > 0 ? (
                    <Box
                      className="selected-filters"
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                        marginTop: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      {/* Selected Categories */}
                      {checkedCategories.map((id) => {
                        const category = productStats?.categories?.find(
                          (item) => item._id === id
                        );
                        return (
                          <Chip
                            key={id}
                            label={category?.title}
                            onDelete={() => handleCheckboxChange(id)}
                            sx={{
                              backgroundColor: "#8E282B",
                              color: "#fff",
                            }}
                          />
                        );
                      })}

                      {/* Selected Colors */}
                      {selectedColors.map((color) => (
                        <Chip
                          key={color}
                          label={getColorNameByCode(color)}
                          onDelete={() => handleColorClick(color)}
                          sx={{
                            backgroundColor: color,
                            color: "#fff",
                          }}
                        />
                      ))}
                    </Box>
                  ) : (
                    <>{""}</>
                  )}

                  <Drawer
                    anchor="left"
                    open={openFilter}
                    onClose={toggleDrawer}
                    className="filter_drawer"
                  >
                    <div className="full_Div">
                      <div className="head">
                        <Typography variant="h6">FILTER</Typography>
                        <IconButton onClick={toggleDrawer}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <Divider sx={{ my: 2 }} />

                      <Typography className="subtitle1">Price</Typography>
                      <Slider
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={+productStats?.maxPrice}
                        sx={{ color: "#8E282B" }}
                      />
                      <Typography
                        className="subtitle1"
                        style={{ fontSize: 14 }}
                        align="center"
                      >
                        Price: ₹{0} - ₹{+productStats?.maxPrice}
                      </Typography>
                      <Divider sx={{ my: 2 }} />

                      <Typography className="subtitle1">
                        Select Category
                      </Typography>
                      <Box className="panel_content">
                        <List className="categories">
                          {productStats?.categories?.map((data, i) => (
                            <ListItem key={i}>
                              <FormControlLabel
                                key={data._id}
                                control={
                                  <Checkbox
                                    checked={checkedCategories.includes(
                                      data._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(data._id)
                                    }
                                    name={data.title}
                                    sx={{
                                      color: "#8E282B",
                                      "&.Mui-checked": {
                                        color: "#8E282B",
                                      },
                                    }}
                                  />
                                }
                                label={data.title}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Typography className="subtitle1">
                        Select Color
                      </Typography>
                      <Box className="panel_content">
                        <div className="colors">
                          {productStats?.colors?.map((color) => (
                            <div key={color} className="color-item">
                              <div
                                className={`hex ${
                                  selectedColors.includes(color)
                                    ? "selected"
                                    : ""
                                }`}
                                style={{ background: color }}
                                onClick={() => handleColorClick(color)}
                              ></div>
                              <div className="color-name">
                                {getColorNameByCode(color)}
                              </div>
                            </div>
                          ))}
                        </div>
                      </Box>
                    </div>
                  </Drawer>
                </>
                <Grid
                  container
                  padding={0}
                  columnSpacing={2}
                  rowSpacing={2}
                  className="look_container"
                >
                  {allProducts?.length > 0 ? (
                    allProducts?.map((product) => {
                      return <ProductCard product={product} />;
                    })
                  ) : (
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          sx={{ fontWeight: "bold" }}
                        >
                          No Result Found.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {/* Ref for the last product to trigger more load */}
                  <div
                    ref={lastProductRef}
                    style={{ height: "50px", margin: "20px 0" }}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ProductsList
          apiUrl={URL_CONSTANTS.getNewArrival}
          text1={"NEW"}
          text2={"ARRIVAL"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.newArrivals}
        />
        <ProductsList
          apiUrl={URL_CONSTANTS.getBestSeller}
          text1={"BEST"}
          text2={"SELLER"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.bestSeller}
        />
        <Footer />
      </Box>
    </>
  );
};

export default ProductsData;
